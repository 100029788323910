import React, { useEffect, useRef } from "react";
import style from "./EducationalVideoItem.module.sass";
import Button from "../../Button/Button";
function EducationalVideoItem(props) {
  const { id, clickOnAdd, number, title, buttons, isAssigned, duration, durationLabel, imageUrl } =
    props;
  return (
    <div
      className={`${style.EducationalVideoItem}`}
      data-id={id}
    >
      <div className={`${style.mainContainer}`}>
        <div className={`${style.imageContainer}`}>
          <img src={imageUrl} />
        </div>
        <div className={`${style.nameContainer}`}>
          <div className={`${style.title}`}>
            <div>{title}</div>
          </div>
          {
            duration &&
            <div className={`${style.duration}`}>
              {`${durationLabel}: ${duration}`}
            </div>
          }
        </div>

        <div className={`${style.buttonAdd}`}>
          <Button
            kariBtnGreenOver
            kariBtnDisable={isAssigned}
            onClick={() => clickOnAdd(id)}
          >
            {buttons.add}
          </Button>
        </div>
      </div>
    </div>
  );
}

EducationalVideoItem.propTypes = {};

EducationalVideoItem.defaultProps = {
  code: "TYPE_BAL",
};

export default EducationalVideoItem;
