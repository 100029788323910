import React from "react";

const MedicairRespiratoryLabel = (props) => {
  const backgroundColor = () => {
    if (props.code == 'REPETITIONS') {
      return '#FB5872'
    } else {
      return '#58DEFB'
    }

  }

  return (
    <div className={`badge badge-secondary text-uppercase`}
      style={{ color: backgroundColor(), backgroundColor: '#16181C' }}>
      {props.title}
    </div>
  );
};

export { MedicairRespiratoryLabel as default };
