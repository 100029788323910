import React, { useEffect, useReducer } from "react";
import style from "./PersonalSequencePage.module.sass";
import PersonalSequencePageHeader from "./PersonalSequencePageHeader/PersonalSequencePageHeader";
import SessionListContainer from "./SessionListContainer/SessionListContainer";
import SessionListContainerHorizontal from "./SessionListContainerHorizontal/SessionListContainerHorizontal";
import Sequences from "./Sequences/Sequences";
import NewSessionForm from "./NewSessionForm/NewSessionForm";
import { callApi } from "../../helpers";
import Button from "../Button/Button";

const initialState = {
  newSessionForm: {
    state: "instructions",
    indexes: [],
    aerobicIndexes: [],
    respiratoryIndexes: [],
  },
  savedSequences: [],
  personalSequences: [],
  rental: {
    sessions: [],
  },
  selectedSessionList: null
};

function reducer(state, action) {
  const newState = JSON.parse(JSON.stringify(action));
  state = { ...state, ...newState };
  return state;
}

function PersonalSequencePage(props) {
  const { header, patientId } = props;
  const [state, setState] = useReducer(reducer, initialState);

  const getIndexesParamsString = (indexes) => {
    return "sessions_indexes[]=" + indexes.join("&sessions_indexes[]=");
  };

  const getAerobicIndexesParamsString = (indexes) => {
    return "aerobic_sessions_indexes[]=" + indexes.join("&aerobic_sessions_indexes[]=");
  };

  const getRespiratoryIndexesParamsString = (indexes) => {
    return "respiratory_sessions_indexes[]=" + indexes.join("&respiratory_sessions_indexes[]=");
  };

  const createSequence = (
    savedSequenceId = null,
    refresh = true,
    onEndUpdateSesssion
  ) => {
    const { personalSequencesUrl } = state;
    let url = `${personalSequencesUrl}`;
    if (savedSequenceId) {
      url += `?saved_sequence_id=${savedSequenceId}`;
    }
    callApi(url, "POST", "json", (data) => {
      updateSessions(data.personal_sequence_id, onEndUpdateSesssion, refresh);
    });
  };

  const createNewSequence = () => {
    const { newSessionForm, newUrl, rental } = state;
    let url = `${newUrl}`;
    console.warn("Giacomo, ma il rentalId per?");
    url += "&" + getIndexesParamsString(newSessionForm.indexes);
    url += "&rental_id= " + rental.id;
    window.location.href = url;
  };

  const updateSessions = (
    personalSequenceId,
    onSuccess = null,
    refresh = true
  ) => {
    const { newSessionForm, rental } = state;
    if (
      newSessionForm.indexes.length > 0 ||
      newSessionForm.aerobicIndexes.length > 0 ||
      newSessionForm.respiratoryIndexes.length > 0
    ) {
      const url = `${rental.sessionsUrl
        }?personal_sequence_id=${personalSequenceId}&${getIndexesParamsString(
          newSessionForm.indexes
        )}&${getAerobicIndexesParamsString(
          newSessionForm.aerobicIndexes
        )}&${getRespiratoryIndexesParamsString(
          newSessionForm.respiratoryIndexes
        )}`;
      callApi(
        url,
        "PATCH",
        "json",
        (data) => onUpdateSessionSuccess(data, onSuccess, refresh),
        onUpdateSessionError
      );
    }
  };

  const onUpdateSessionSuccess = (data, onSuccess, refresh) => {
    if (onSuccess) {
      onSuccess();
    }
    if (refresh) {
      refreshComponent(data);
    }
  };

  const onUpdateSessionError = (data) => {
    console.log(data);
  };

  useEffect(() => {
    callMainJSON();
  }, []);

  const callMainJSON = () => {
    console.log("callMainJSON");
    callApi(
      `/personal_sequence_area/patients/${patientId}/personal_sequences.json`,
      "GET",
      "json",
      onCallSuccess
    );
  };

  const onCallSuccess = (data) => {
    console.log("onCallSuccess");
    console.log(data);
    setState(data);
  };

  const refreshComponent = (data) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.newSessionForm.indexes = [];
    newState.newSessionForm.aerobicIndexes = [];
    newState.newSessionForm.respiratoryIndexes = [];
    newState.newSessionForm.state = "instructions";
    newState.selectedSessionList = null;
    setState(newState);
    callMainJSON();
  };

  console.log("Personal Sequence Page");

  return (
    <div className={`${style.PersonalSequence}`}>
      <PersonalSequencePageContext.Provider
        value={{
          state: state,
          setState,
          updateSessions,
          createSequence,
          createNewSequence,
          refresh: callMainJSON,
        }}
      >
        {/* <PersonalSequencePageHeader {...header} /> // REMOVED AS USELESS */}
        <div className={`${style.PersonalSequencePageContainer}`}>
          <div className={`row`}>
            <div className={'col-md-4'}>
              <Button kariBtnWhite href={header.backBtn.url}>
                {header.backBtn.text}
              </Button>
              <SessionListContainer />
            </div>
            <div className={'col-md-8 pt-4'}>
              <div className={`${style.patientBlock} ${style.short}`}>
                <div className={`${style.name}`}>{state.patient?.name}</div>
              </div>

              <div className={`${style.session}`}>
                <div className={`${style.sessionTitle}`}>
                  {state.patient?.isMedicair ? state.translations?.sessionsKari : state.translations?.sessions}
                </div>
                <SessionListContainerHorizontal type='kari' />
                {
                  state.patient?.isMedicair &&
                  <div>
                    <div className={`${style.sessionTitle}`}>
                      {state.translations?.sessionsAerobic}
                    </div>
                    <SessionListContainerHorizontal type='aerobic' />
                    <div className={`${style.sessionTitle}`}>
                      {state.translations?.sessionsRespiratory}
                    </div>
                    <SessionListContainerHorizontal type='respiratory' />
                  </div>
                }

                <div className={`mt-3`}>
                  <Sequences />
                </div>
                <div className={`mt-3`}>
                  <NewSessionForm {...state.translations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PersonalSequencePageContext.Provider>
    </div>
  );
}

PersonalSequencePage.propTypes = {};

PersonalSequencePage.defaultProps = {};

export const PersonalSequencePageContext = React.createContext({});
export default PersonalSequencePage;
