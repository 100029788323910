import React from "react";
import classes from './SliderDurationDeviceRecording.module.sass'

function SliderDurationDeviceRecording(props) {
  const {
    label,
    value,
    units,
  } = props

  const displayValue = () => {
    const denominator = value > 3600 ? 3600 : 60
    return (
      <div className={classes.value}>
        {(value / denominator).toFixed(0)}
      </div>
    )
  }

  const displayUnit = () => {
    const unit = value > 3600 ? units.hours : units.minutes
    return (
      <div className={classes.unit}>
        {unit}
      </div>
    )
  }

  return (
    <div className="row text-center border-start border-end">
      <div className="d-flex justify-content-center w-100">
        <div>
          {displayValue()}
        </div>
        <div>
          <div>
            <div className={classes.label}>{label}</div>
            <div className="d-flex">
              {/* <span className={`${classes.separator} me-1`}>{separator}</span> */}
              {displayUnit()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderDurationDeviceRecording