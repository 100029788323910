import React from "react";
import style from "./AssignedEducationalVideoItem.module.sass";
import CloseIcon from "./assets/cross_circle.svg";

function AssignedEducationalVideoItem(props) {
  const { title, removeUrl, removeItem } = props;

  // console.log(props);

  const onClickRemove = () => {
    removeItem(removeUrl);
  };

  const renderCloseBnt = () => {
    return (
      <div className={`${style.closeIcon}`} onClick={onClickRemove}>
        <img src={CloseIcon} />
      </div>
    );
  };

  return (
    <div className={`${style.AssignedEducationalVideoItem}`}>
      <div className={`${style.name}`}>
        {title}
      </div>
      {renderCloseBnt()}
    </div>
  );
}

AssignedEducationalVideoItem.propTypes = {};

AssignedEducationalVideoItem.defaultProps = {};

export default AssignedEducationalVideoItem;
