import React, { useEffect, useReducer, useRef, useState } from "react";
import style from "./AssignedEducationalVideos.module.sass";
import List from "../List/List";
import EducationalVideoItem from "./EducationalVideoItem/EducationalVideoItem"
import { callApi, debounce, triggerTextChange, updateUrlWithRandomParam } from "../../helpers";
import AssignedEducationalVideoItem from "./AssignedEducationalVideoItem/AssignedEducationalVideoItem";

import Button from "../Button/Button";
function AssignedEducationalVideos(props) {
  const { patientId, searchUrl, addUrl, patientVideosUrl, translations, patientUrl } = props;
  const [queryUrl, setQueryUrl] = useState(
    searchUrl
  );
  const [assignedVideosUrl, setAssignedVideosUrl] = useState(
    patientVideosUrl
  );
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const assignedEducationalVideosListRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", onResize.bind(this));
    onResize();
  }, []);

  const onResize = () => {
    const blockBoundingBox = assignedEducationalVideosListRef.current.getBoundingClientRect();
    const newHeight = window.innerHeight - blockBoundingBox.y;
    assignedEducationalVideosListRef.current.style.height = `${newHeight}px`;
  };

  const clickOnAddItem = (genericVideoId) => {
    callApi(
      addUrl,
      "POST",
      "json",
      onItemAdded,
      onError,
      onFinally,
      {
        body: JSON.stringify({
          assigned_educational_video: {
            patient_id: patientId,
            generic_video_id: genericVideoId,
          }
        }),
      }
    );
  };

  const onItemAdded = () => {
    // update both lists
    setAssignedVideosUrl(updateUrlWithRandomParam(assignedVideosUrl))
    setQueryUrl(updateUrlWithRandomParam(queryUrl))
  };

  const onError = () => {
    // do something
  };

  const onFinally = () => {
    // do something
  };

  const removeItem = (removeUrl) => {
    callApi(removeUrl, "DELETE", "", onItemRemoved);
  };

  const onItemRemoved = () => {
    // update both lists
    setAssignedVideosUrl(updateUrlWithRandomParam(assignedVideosUrl))
    setQueryUrl(updateUrlWithRandomParam(queryUrl))
  };

  const renderItem = (item) => {
    return <EducationalVideoItem
      {...item}
      clickOnAdd={clickOnAddItem}
      patientId={patientId}
      isAssigned={item.assigned} />;
  };

  const onItemsLoaded = (items, data) => {
    // console.log("items", items);
    // console.log("data", data);
    setIsLoading(false);
  };

  const renderAssignedItem = (item) => {
    return <AssignedEducationalVideoItem {...item} removeItem={removeItem} />;
  };

  const onAssignedItemsLoaded = (items, data) => {
    // console.log("items", items);
    // console.log("data", data);
    setIsLoading(false);
  };

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        setQueryUrl(searchUrl + '?key=' + text)
      },
      search
    );
  }, 200);

  return (
    <div className={`${style.AssignedEducationalVideos}`}>
      <AssignedEducationalVideosContext.Provider
        value={{ removeItem, translations, isLoading }}
      >
        <div className={`${style.AssignedEducationalVideosHeader}`}></div>
        <div className={`${style.AssignedEducationalVideosContent}`}>
          <div className={`${style.AssignedEducationalVideosContentLeft}`}>
            <div className={`${style.AssignedEducationalContentLeftTop}`}>
              <Button kariBtnWhite href={patientUrl}>
                {translations.backToPatient}
              </Button>
              <div className={`${style.AssignedEducationalVideosSearch}`}>
                <i
                  className={`fa fa-search ${style.iconSearch}`}
                ></i>
                <input
                  id="patient-search"
                  className={`form-control input-kari ps-5`}
                  onChange={handleSearchChange}
                  type={"text"}
                  placeholder={translations.searchBarPlaceholder}
                />
              </div>
            </div>
            <div className={`${style.AssignedEducationalVideosContentLeftIn}`}>
              <div
                className={`${style.AssignedEducationalVideosContentLeftInScroll}`}
                ref={assignedEducationalVideosListRef}
              >
                <List
                  baseQuery={queryUrl}
                  renderItem={renderItem}
                  showPagination={true}
                  onItemsLoaded={onItemsLoaded}
                />
              </div>
            </div>
          </div>
          <div className={`${style.AssignedEducationalVideosContentRight}`}>
            <div className={`${style.yourVideosTitle}`}> {translations.assignedVideosTitle}</div>
            <div className={`${style.yourVideosList}`}>
              <List
                baseQuery={assignedVideosUrl}
                renderItem={renderAssignedItem}
                showPagination={false}
                onItemsLoaded={onAssignedItemsLoaded}
              />
            </div>
          </div>
        </div>
      </AssignedEducationalVideosContext.Provider>
    </div>
  );
}

AssignedEducationalVideos.propTypes = {};

AssignedEducationalVideos.defaultProps = { showFilters: true };

export const AssignedEducationalVideosContext = React.createContext({});

export default AssignedEducationalVideos;
