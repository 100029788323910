import React, { useEffect, useRef } from "react";
import style from "./ExerciseItem.module.sass";
import SequenceTypology from "../../Sequence/SequenceTypology";
import ToolLabel from "./ToolLabel/ToolLabel";
import Button from "../../Button/Button";
import MedicairRespiratoryLabel from "./MedicairRespiratoryLabel/MedicairRespiratoryLabel";

function ExerciseItem(props) {
  const {
    id,
    clickOnAdd,
    number,
    typology,
    tool,
    title,
    videoSrc,
    videoThumbSrc,
    buttons,
    durationMedicairLabel,
    medicairRespiratoryFirstLine,
    medicairRespiratoryLabel
  } = props;

  const renderFirstLineText = () => {
    if (typology.code == 'TYPE_MEDICAIR') return durationMedicairLabel;
    if (typology.code == 'TYPE_MEDICAIR_RESPIRATORY') return medicairRespiratoryFirstLine;
    return `ID.${number}`
  }

  const renderLabel = () => {
    if (typology.code == 'TYPE_MEDICAIR') return <ToolLabel code={tool.code} title={tool.title} />;
    if (typology.code == 'TYPE_MEDICAIR_RESPIRATORY') return <MedicairRespiratoryLabel code={medicairRespiratoryLabel.code} title={medicairRespiratoryLabel.title} />;
    return <SequenceTypology code={typology.code} name={typology.title} />
  }

  return (
    <div
      className={`${style.ExerciseItem}`}
      data-id={id}
      data-video-src={videoSrc}
    >
      <div className={`${style.mainContainer}`}>
        <img src={videoThumbSrc} className={`${style.media}`} />
        <div className={`${style.nameContainer}`}>
          <div className={`${style.id}`}>{renderFirstLineText()}</div>
          <div className={`${style.name}`}>
            <div>{title}</div>
            {/* FIXME: Improve this elegant crap */}
            {renderLabel()}
          </div>
        </div>

        <div className={`${style.buttonTo}`}>
          <Button
            kariBtn
            kariBtnGreenOver
            kariBtnArrowRight
            onClick={() => clickOnAdd(id)}
          >
            {buttons.add}
          </Button>
        </div>
      </div>
    </div>
  );
}

ExerciseItem.propTypes = {};

ExerciseItem.defaultProps = {
  code: "TYPE_BAL",
};

export default ExerciseItem;
