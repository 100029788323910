import React, { Fragment } from "react";
import SliderProgressBar from "./SliderProgressBar";
import SliderStatus from "./SliderStatus";
import SliderDoneAndTodo from "./SliderDoneAndTodo";
import SliderDoneAndTodoNew from "./SliderDoneAndTodoNew";
import SliderIconTitleSubtitle from "./SliderIconTitleSubtitle";
import SliderDuration from "./SliderDuration";
import SliderDurationMedicair from "./SliderDurationMedicair";
import classes from "./SliderDetails.module.sass";
import ExercisesBar from "../ExercisesBar/ExercisesBar";
import HeartIcon from "./assets/heart.svg";
import OxygenSaturationIcon from "./assets/oxygen.svg";
import DistanceIcon from "./assets/distance.svg";
import StepsIcon from "./assets/steps.svg";
import alertRed from "./assets/alert_red.svg";
import alertYellow from "./assets/alert_yellow.svg";
import SliderToolIcon from "./SliderToolIcon";
import SliderDurationDeviceRecording from "./SliderDurationDeviceRecording";

function SliderDetails(props) {
  const {
    colors,
    date,
    isMedicair,
    isDeviceRecording,
    isMedicairRespiratory,
    medicairData,
    deviceRecordingData,
    hasAlerts,
    alertColors,
    progressBar,
    status,
    stats,
    scoreFormatted,
    statusSlider,
    type,
  } = props;

  const renderDailySequence = () => {
    if (isMedicairRespiratory) return renderMedicairRespiratory();
    if (isMedicair) return renderMedicair();
    return renderSlider()
  };

  const renderKKDailySequence = () => {
    return (
      isMedicair ? renderMedicair() : renderKariConnect()
    );
  };

  const renderDuration = () => {
    return (
      (isMedicair || isMedicairRespiratory) ? renderDurationMedicair() : renderDurationKari()
    );
  };

  const renderDurationMedicair = () => {
    return (
      <Fragment>
        <div className="col-lg-2 align-self-center my-3 my-lg-0">
          <SliderDurationMedicair {...stats.duration} />
        </div>
      </Fragment>
    );
  };

  const renderDurationKari = () => {
    return (
      <Fragment>
        <div className="col-lg-3 align-self-center my-3 my-lg-0">
          <SliderDuration {...stats.duration} />
        </div>
      </Fragment>
    );
  };

  const heartRateMinutesInRangeText = () => {
    // use this to include time in range for warmup/cooldown
    let minutesInLowRange = 0;

    if (medicairData.heartRateMinutesInRangeForIntensity.length == 0) {
      return `${medicairData.heartRateMinutesInRange} min`;
    }

    if (medicairData.heartRateMinutesInRangeForIntensity.length == 2) {
      // use this to exclude time in range for warmup/cooldown
      return `${medicairData.heartRateMinutesInRangeForIntensity[1]} min`;

      // use this to include time in range for warmup/cooldown
      // minutesInLowRange = medicairData.heartRateMinutesInRangeForIntensity.reduce((a, b) => a + b, 0);
      // return `${minutesInLowRange} min`;
    }

    if (medicairData.heartRateMinutesInRangeForIntensity.length == 3) { // this means interval training

      // NOTE:
      // in this case we include time in range for warmup/cooldown and put it in the L field
      // then if the patient is high intensity the field that matters is the H, otherwith it is the L
      // see https://euleria.monday.com/boards/2647999750/pulses/4564254314/posts/2286253670

      // use this to exclude time in range for warmup/cooldown
      // return `${medicairData.heartRateMinutesInRangeForIntensity[1]}' L - ${medicairData.heartRateMinutesInRangeForIntensity[2]}' H`;

      if (medicairData.trainingIntensity == 'medium') {
        return `${medicairData.heartRateMinutesInRangeForIntensity[0]}' L - ${medicairData.heartRateMinutesInRangeForIntensity[1]}' M`;
      }
      // use this to include time in range for warmup/cooldown
      minutesInLowRange = medicairData.heartRateMinutesInRangeForIntensity.slice(0, 2).reduce((a, b) => a + b, 0);
      return `${minutesInLowRange}' L - ${medicairData.heartRateMinutesInRangeForIntensity[2]}' H`;
    }

    if (medicairData.heartRateMinutesInRangeForIntensity.length == 4) {
      // use this to exclude time in range for warmup/cooldown
      return `${medicairData.heartRateMinutesInRangeForIntensity[1]}' L - ${medicairData.heartRateMinutesInRangeForIntensity[2]}' M - ${medicairData.heartRateMinutesInRangeForIntensity[3]}' H`;

      // use this to include time in range for warmup/cooldown
      // minutesInLowRange = medicairData.heartRateMinutesInRangeForIntensity.slice(0, 2).reduce((a, b) => a + b, 0);
      // return `${minutesInLowRange}' L - ${medicairData.heartRateMinutesInRangeForIntensity[2]}' M - ${medicairData.heartRateMinutesInRangeForIntensity[3]}' H`;
    }

  }

  // This function is used for DailySequence in case of MedicairRespiratory sequence
  const renderMedicairRespiratory = () => {
    return (
      <Fragment>
        <div className="col-lg-8 align-self-center">
          <div className="row me-1">
            <div className="col-lg-4 align-self-center ps-4">
              <SliderIconTitleSubtitle
                icon={OxygenSaturationIcon}
                title={`${medicairData.oxygenSaturationMinutesOutOfRange} min`}
                subtitle={medicairData.outOfRangeLabel}
              />
            </div>
            <div className="col-lg-8 d-flex align-self-center">
              <span className={classes.respExName}>{medicairData.sequenceTitle}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  // This function is used for both DailySequence and KariConnectDailySequence
  const renderMedicair = () => {
    const isOutdoor = medicairData.isOutdoor;
    return (
      <Fragment>
        <div className="col-lg-8">
          <div className="row me-5">
            <div className="col-lg-3 d-flex align-self-center my-3 my-lg-0 text-center text-lg-left">
              <span className={classes.effort}>{medicairData.maxEffort}</span>
              <div className="mt-2">
                <div className={classes.effortLabel}>{medicairData.effortLabelLine1}</div>
                <div className={classes.effortLabel}>{medicairData.effortLabelLine2}</div>
              </div>
            </div>
            <div className="col-lg-4 align-self-center my-3 my-lg-0">
              <SliderIconTitleSubtitle
                icon={isOutdoor ? DistanceIcon : HeartIcon}
                title={isOutdoor ? `${(medicairData.distance / 1000).toFixed(1)} km` : heartRateMinutesInRangeText()}
                subtitle={isOutdoor ? medicairData.distanceLabel : medicairData.inRangeLabel}
              />
            </div>
            <div className="col-lg-4 align-self-center my-3 my-lg-0">
              <SliderIconTitleSubtitle
                icon={isOutdoor ? StepsIcon : OxygenSaturationIcon}
                title={isOutdoor ? medicairData.steps : `${medicairData.oxygenSaturationMinutesOutOfRange} min`}
                subtitle={isOutdoor ? medicairData.stepsLabel : medicairData.outOfRangeLabel}
              />
            </div>
            <div className="col-lg-1 align-self-center my-3 my-lg-0">
              <SliderToolIcon
                toolCode={medicairData.toolUsedCode}
                toolUsed={medicairData.toolUsed}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderSlider = () => {
    return (
      <Fragment>
        <div className="col-lg-1 align-self-center my-3 my-lg-0 text-center text-lg-left">
          <span className={classes.percentage}
            style={{ color: colors.textColor }}>
            {scoreFormatted}
          </span>
        </div>
        <div className="col-lg-4 align-self-center my-3 my-lg-0">
          <div className="row">
            <div className="col-12 col-lg-10 align-self-center mb-lg-0 mb-2 text-center">
              <div className="col position-relative">
                <SliderProgressBar
                  {...progressBar}
                  progressBarBackgroundColor={colors.progressBarBackgroundColor}
                  statusSlider={statusSlider}
                />
              </div>
            </div>
            <div className="col-12 col-lg-2 align-self-center text-center text-lg-left">
              <SliderStatus {...status} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  const renderKariConnect = () => {
    const { badges, exercisesLabel, done, todo } = props.stats.exercises;
    return (
      <Fragment>
        <div className="col-lg-2 align-self-center my-3 my-lg-0 text-center text-lg-left pe-0">
          <div
            className={classes.dailyDoneTodo + " text-end"}
            style={{ color: colors.textColor }}
          >{`${done}/${todo}`}</div>
          <div
            className={classes.dailyDoneTodoText + " text-end"}
          >{`${exercisesLabel}`}</div>
        </div>
        <div className="col-lg-5 align-self-center text-center text-lg-left my-3 my-lg-0">
          <ExercisesBar exercises={badges}
            backgroundColor={colors.progressBarBackgroundColor}
            barCompletedColor={colors.progressBarCompletedColor}
            barCompletedColorLight={colors.progressBarCompletedColorLight}
            textColor={colors.textColor} />
        </div>
      </Fragment>
    );
  };

  // This function is used for both DailySequence and KariConnectDailySequence
  const renderDeviceRecording = () => {
    return (
      <Fragment>
        <div className="col-lg-10">
          <div className={`${classes.deviceRecording}`}>
            <div className={`${classes.deviceRecordingIconTitle}`}>
              <div className="ps-4 me-1">
                <div>
                  <img className={`${classes.icon} mx-2`} src={OxygenSaturationIcon}></img>
                </div>
              </div>
              <div className={classes.deviceRecordingTitle}>
                {deviceRecordingData.title}
              </div>
            </div>
            <div className={`${classes.deviceRecordingDownloadDuration}`}>
              <div className={classes.deviceRecordingDownload}>
                <a href={deviceRecordingData.download.pdf.url} target="_blank" >
                  <i className="fas fa-cloud-download-alt"></i> {deviceRecordingData.download.pdf.label}
                </a>
                {/* <a href={deviceRecordingData.download.csv.url} target="_blank" >
                  <i className="fas fa-cloud-download-alt"></i> {deviceRecordingData.download.csv.label}
                </a> */}
                <a href={deviceRecordingData.download.json.url} target="_blank" >
                  <i className="fas fa-cloud-download-alt"></i> {deviceRecordingData.download.json.label}
                </a>
              </div>
              <div className={classes.deviceRecordingDuration}>
                <SliderDurationDeviceRecording {...deviceRecordingData.duration} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderSliderDetails = () => {
    switch (type) {
      case "daily_sequence":
      case "xclinic_sequence":
        return renderDailySequence();
      case "device_recording":
        return renderDeviceRecording();
      default:
        return renderKKDailySequence();
    }
  }

  const renderDoneExercises = () => {
    if ((isMedicair || isMedicairRespiratory || isDeviceRecording) || type == "kari_connect_daily_sequence") {
      // this info is 
      // 1. useless for Medicair
      // 2. rendered in renderKariConnect() for KK
      return;
    }

    if (type == "xclinic_sequence") {
      return (
        <Fragment>
          <div className="col ms-3 align-self-center text-center text-lg-left my-3 my-lg-0">
            <SliderDoneAndTodoNew {...stats.exercises} />
          </div>
          <div className="col border-end">
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className="col-lg-2 align-self-center text-center text-lg-left my-3 my-lg-0">
          <SliderDoneAndTodo {...stats.exercises} />
        </div>
      );
    }
  }

  const renderAlert = () => {
    if (hasAlerts)
      return (
        <div className="position-relative">
          {
            alertColors.includes('red') &&
            <div className={`${classes.alertRed}`}>
              <img src={alertRed} />
            </div>
          }
          {
            alertColors.includes('yellow') &&
            <div className={`${classes.alertYellow}`}>
              <img src={alertYellow} />
            </div>
          }
        </div>
      )
  }

  return (
    <div className="d-flex" style={{ minHeight: 52 + "px" }}>
      {renderSliderDetails()}
      {renderDoneExercises()}
      {!["xclinic_sequence", "device_recording"].includes(type) && renderDuration()}
      <div className="col-lg-2 align-self-center text-center text-lg-left my-3 my-lg-0">
        <div className={classes.date}>{date.fulldate}</div>
        <div className={classes.weekDay}>{date.weekday}</div>
      </div>
      {renderAlert()}
    </div>
  );
}

export default SliderDetails;
